// import React, { useState, useEffect } from "react";
// import { Link, useNavigate } from "react-router-dom"; // Import du composant Link et useNavigate
// import "./Navbar.css";
// import logo from "./assets/logo.png";
// import mobileLogo from "./assets/logo-icon.png";

// const Navbar = () => {
//   const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
//   const [isMenuOpen, setIsMenuOpen] = useState(false);
//   const navigate = useNavigate(); // Hook pour la navigation

//   useEffect(() => {
//     const handleResize = () => {
//       setIsMobile(window.innerWidth < 768);
//     };

//     window.addEventListener("resize", handleResize);

//     return () => {
//       window.removeEventListener("resize", handleResize);
//     };
//   }, []);

//   const toggleMenu = () => {
//     setIsMenuOpen(!isMenuOpen);
//   };

//   // Fonction pour rediriger et scroller vers une section donnée
//   const handleNavigation = (sectionId) => {
//     navigate("/"); // Redirection vers la page d'accueil
//     setTimeout(() => {
//       const section = document.getElementById(sectionId); // Recherche de la section par ID
//       if (section) {
//         section.scrollIntoView({ behavior: "smooth" }); // Scroll vers la section
//       }
//     }, 100); // Petit délai pour assurer que la redirection est effectuée
//     if (isMobile) {
//       setIsMenuOpen(false); // Ferme le menu sur mobile
//     }
//   };

//   return (
//     <nav className="navbar">
//       <div className="navbar-logo">
//         <Link to="/">
//           {/* Ajout de la redirection vers la page d'accueil */}
//           {isMobile ? (
//             <img src={mobileLogo} alt="Mobile Logo" className="logo-mobile" />
//           ) : (
//             <img src={logo} alt="Desktop Logo" className="logo-desktop" />
//           )}
//         </Link>
//       </div>
//       {isMobile ? (
//         <div className="navbar-burger" onClick={toggleMenu}>
//           <div className={`burger-line ${isMenuOpen ? "open" : ""}`}></div>
//           <div className={`burger-line ${isMenuOpen ? "open" : ""}`}></div>
//           <div className={`burger-line ${isMenuOpen ? "open" : ""}`}></div>
//         </div>
//       ) : (
//         <ul className="navbar-links">
//           <li>
//             <button onClick={() => handleNavigation("portfolio")}>
//               Portfolio
//             </button>
//           </li>
//           <li>
//             <button onClick={() => handleNavigation("pricing")}>Pricing</button>
//           </li>
//           <li>
//             <button onClick={() => handleNavigation("faq")}>FAQ</button>
//           </li>
//           <li>
//             <button
//               className="btn-primary"
//               onClick={() => handleNavigation("pricing")}
//             >
//               See Plans
//             </button>
//           </li>
//         </ul>
//       )}
//       {isMenuOpen && isMobile && (
//         <ul className="navbar-links-mobile">
//           <li>
//             <button onClick={() => handleNavigation("portfolio")}>
//               Portfolio
//             </button>
//           </li>
//           <li>
//             <button onClick={() => handleNavigation("pricing")}>Pricing</button>
//           </li>
//           <li>
//             <button onClick={() => handleNavigation("faq")}>FAQ</button>
//           </li>
//           <li>
//             <button
//               className="btn-primary"
//               onClick={() => handleNavigation("pricing")}
//             >
//               See Plans
//             </button>
//           </li>
//         </ul>
//       )}
//     </nav>
//   );
// };

// export default Navbar;
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom"; // Import du composant Link et useNavigate
import "./Navbar.css";
import logo from "./assets/logo.png";
import mobileLogo from "./assets/logo-icon.png";

const Navbar = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate(); // Hook pour la navigation

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // Fonction pour rediriger vers une section donnée
  const handleNavigation = (sectionId) => {
    navigate("/"); // Redirection vers la page d'accueil
    setTimeout(() => {
      const section = document.getElementById(sectionId); // Recherche de la section par ID
      if (section) {
        section.scrollIntoView({ behavior: "smooth" }); // Scroll vers la section
      }
    }, 100); // Petit délai pour assurer que la redirection est effectuée
    if (isMobile) {
      setIsMenuOpen(false); // Ferme le menu sur mobile
    }
  };

  return (
    <nav className="navbar">
      <div className="navbar-logo">
        <Link to="/">
          {/* Redirection vers la page d'accueil */}
          {isMobile ? (
            <img src={mobileLogo} alt="Mobile Logo" className="logo-mobile" />
          ) : (
            <img src={logo} alt="Desktop Logo" className="logo-desktop" />
          )}
        </Link>
      </div>
      {isMobile ? (
        <div className="navbar-burger" onClick={toggleMenu}>
          <div className={`burger-line ${isMenuOpen ? "open" : ""}`}></div>
          <div className={`burger-line ${isMenuOpen ? "open" : ""}`}></div>
          <div className={`burger-line ${isMenuOpen ? "open" : ""}`}></div>
        </div>
      ) : (
        <ul className="navbar-links">
          <li>
            <button onClick={() => navigate("/portfolio")}>Portfolio</button>{" "}
            {/* Utilisation de navigate */}
          </li>
          <li>
            <button onClick={() => handleNavigation("pricing")}>Pricing</button>
          </li>
          <li>
            <button onClick={() => handleNavigation("faq")}>FAQ</button>
          </li>
          <li>
            <button
              className="btn-primary"
              onClick={() => handleNavigation("pricing")}
            >
              See Plans
            </button>
          </li>
        </ul>
      )}
      {isMenuOpen && isMobile && (
        <ul className="navbar-links-mobile">
          <li>
            <button onClick={() => navigate("/portfolio")}>Portfolio</button>{" "}
            {/* Utilisation de navigate */}
          </li>
          <li>
            <button onClick={() => handleNavigation("pricing")}>Pricing</button>
          </li>
          <li>
            <button onClick={() => handleNavigation("faq")}>FAQ</button>
          </li>
          <li>
            <button
              className="btn-primary"
              onClick={() => handleNavigation("pricing")}
            >
              See Plans
            </button>
          </li>
        </ul>
      )}
    </nav>
  );
};

export default Navbar;
