import React from "react";
import "./Header.css";

const Header = () => {
  return (
    <header className="header">
      <h1>
        Made to Be <span className="highlight">Memorable</span>
      </h1>
      <p>
        We create logos that leave a lasting impression. Made by top designers,
        starting at $80, <u>delivered in 48 hours</u>.
      </p>
      <div className="header-buttons">
        <a href="#pricing">
          <button className="btn-secondary">See Plans</button>
        </a>
        <a href="#pricing">
          <button className="btn-primary">Order Now</button>
        </a>
      </div>
    </header>
  );
};

export default Header;
