import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import "./PricingSection.css";

const stripePromise = loadStripe(
  "pk_live_51OdDIwGxCTtVuGJg2StP9AWQaQDEAOMFsXplkKI8lnXV2RuqIHNpgGgmxpqCrPc22a6RoE7dPBFZtRlPYng77owI00LzKNra8Y"
);

const PricingSection = () => {
  const handleCheckout = async (productType) => {
    try {
      const response = await fetch(
        "https://stripe-checkout-backend-pied.vercel.app/api/create-checkout-session",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ productType }),
        }
      );

      if (!response.ok) {
        throw new Error("Error creating Stripe session");
      }

      const session = await response.json();
      const stripe = await stripePromise;
      const { error } = await stripe.redirectToCheckout({
        sessionId: session.id,
      });

      if (error) {
        console.error("Stripe redirect error:", error);
      }
    } catch (error) {
      console.error("Backend request error:", error);
    }
  };

  return (
    <section id="pricing" className="pricing-section">
      <h2>Simple pricing. Endless ROI.</h2>
      <div className="pricing-cards">
        <div className="pricing-card">
          <h3>Basic</h3>
          <p className="price-current">
            <span className="price-dollar">$80</span>
            <span className="price-tag">Limited Offer</span>
          </p>
          <ul>
            <li className="bold-text">1 Concept</li>
            <li>Logo transparency</li>
            <li>Vector file</li>
            <li>Printable file</li>
            <li>Source file</li>
            <li>2 revisions included</li>
            <li className="bold-text">48h delivery</li>
            <li>3D mockup</li>
            <li>Stationery designs</li>
            <li>Social media kit</li>
          </ul>
          <button
            className="btn-primary"
            onClick={() => handleCheckout("basic")}
          >
            Order now
          </button>
        </div>

        <div className="pricing-card popular">
          <h3>Standard</h3>
          <p className="price-current">
            <span className="price-dollar">$110</span>
            <span className="price-tag">Limited Offer</span>
          </p>
          <ul>
            <li className="bold-text">2 Concepts</li>
            <li>Logo transparency</li>
            <li>Vector file</li>
            <li>Printable file</li>
            <li>Source file</li>
            <li>3 revisions included</li>
            <li className="bold-text">48h delivery</li>
            <li>3D mockup</li>
            <li>Stationery designs</li>
            <li>Social media kit</li>
          </ul>
          <button
            className="btn-primary"
            onClick={() => handleCheckout("standard")}
          >
            Order now
          </button>
        </div>

        <div className="pricing-card premium">
          <h3 className="premium-title">
            Premium<span className="badge-popular">Most Popular</span>
          </h3>
          <p className="price-current">
            <span className="price-dollar">$130</span>
            <span className="price-tag">Limited Offer</span>
          </p>
          <ul>
            <li className="bold-text">3 Concepts</li>
            <li>Logo transparency</li>
            <li>Vector file</li>
            <li>Printable file</li>
            <li>Source file</li>
            <li className="bold-text">Unlimited revisions</li>
            <li className="bold-text">48h delivery</li>
            <li>3D mockup</li>
            <li>Stationery designs</li>
            <li>Social media kit</li>
          </ul>
          <button
            className="btn-primary"
            onClick={() => handleCheckout("premium")}
          >
            Order now
          </button>
        </div>
      </div>
    </section>
  );
};

export default PricingSection;
