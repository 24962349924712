import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./Navbar";
import Header from "./Header";
import LogoCarousel from "./LogoCarousel";
import StepsSection from "./StepsSection";
import PricingSection from "./PricingSection";
import FAQSection from "./FAQSection";
import SuccessPage from "./SuccessPage";
import CancelPage from "./CancelPage";
import PrivacyPolicy from "./PrivacyPolicy";
import TermsOfService from "./TermsOfService";
import Footer from "./Footer";
import Portfolio from "./Portfolio";

import "./App.css";

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        {/* Wrap the routes/content inside main-content */}
        <div className="main-content">
          <Routes>
            <Route
              path="/"
              element={
                <>
                  <Header />
                  <LogoCarousel />
                  <StepsSection />
                  <PricingSection />
                  <FAQSection />
                </>
              }
            />
            <Route path="/success" element={<SuccessPage />} />
            <Route path="/cancel" element={<CancelPage />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-of-service" element={<TermsOfService />} />
            <Route path="/portfolio" element={<Portfolio />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
