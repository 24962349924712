import React from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

function SuccessPage() {
  const location = useLocation();

  // Extraire les paramètres session_id et productType de l'URL
  const queryParams = new URLSearchParams(location.search);
  const sessionId = queryParams.get("session_id");
  const productType = queryParams.get("productType");

  // Définir les prix selon le produit acheté
  const productPrices = {
    basic: 140,
    standard: 190,
    premium: 240,
  };

  const orderAmount = productPrices[productType] || 0;

  return (
    <div className="centered-content">
      <Helmet>
        <script>
          {`
            gtag('event', 'conversion', {
              'send_to': 'AW-16718066953/mZYpCNuFpNcZEInq5aM-',
              'value': ${orderAmount},
              'currency': 'USD',
              'transaction_id': '${sessionId}'
            });
          `}
        </script>
      </Helmet>
      <div>
        <h1>Success</h1>
        <p>Your payment was successful!</p>
        <p>
          Now please check your email and fill the form to complete the
          onboarding process :)
        </p>
      </div>
    </div>
  );
}

export default SuccessPage;
