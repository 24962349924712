import React, { useEffect, useRef } from "react";
import flexioLogo from "./assets/Flexio_Logo_small_2.png";
import linkUpLogo from "./assets/LinkUp_Logo_280824.png";
import snapiLogo from "./assets/Snapi_Logo_Opt4_280824.png";
import advizeLogo from "./assets/Advize_Logo.png";
import akkharWhite from "./assets/Akkhar white.png";
import akkharRed from "./assets/Akkhar red.png";
import tiltLogo from "./assets/0.9x/Plan de travail 1@0.9x.png";
import fekBlack from "./assets/Uchendu/Plan de travail 1@0.8x.png";
import polyforgeBlack from "./assets/Uchendu/Polyforge - 01.png";
import wokoma from "./assets/Uchendu/logo vertical PNG.png";
import fekWhite from "./assets/Uchendu/0.8x/Plan de travail 1@0.8xw.png";
import awdia from "./assets/Uchendu/Awdia.png";
import intact from "./assets/Uchendu/Intact.png";
import intact2 from "./assets/Uchendu/Intact 02.png";
import swift from "./assets/Uchendu/1x/Plan de travail 1swift.png";

import "./LogoCarousel.css";

const Carousel = () => {
  const topRowRef = useRef(null);
  const bottomRowRef = useRef(null);

  useEffect(() => {
    const topRow = topRowRef.current;
    const bottomRow = bottomRowRef.current;

    let topRowScroll = 0;
    let bottomRowScroll = 0;

    const scrollTopRow = () => {
      topRowScroll -= 1; // Ajustez la vitesse ici
      if (topRowScroll <= -topRow.scrollWidth / 2) {
        topRowScroll = 0;
      }
      topRow.style.transform = `translateX(${topRowScroll}px)`;
    };

    const scrollBottomRow = () => {
      bottomRowScroll += 1; // Ajustez la vitesse ici
      if (bottomRowScroll >= bottomRow.scrollWidth / 2) {
        bottomRowScroll = 0;
      }
      bottomRow.style.transform = `translateX(${bottomRowScroll}px)`;
    };

    const intervalTop = setInterval(scrollTopRow, 20);
    const intervalBottom = setInterval(scrollBottomRow, 20);

    return () => {
      clearInterval(intervalTop);
      clearInterval(intervalBottom);
    };
  }, []);

  return (
    <div id="portfolio" className="carousel">
      <div className="carousel-row" ref={topRowRef}>
        <Card logo={linkUpLogo} altText="LinkUp Logo" className="linkup" />
        <Card logo={swift} altText="SwiftGo Logo" className="swift" />

        <Card logo={tiltLogo} altText="Tilt Logo" className="snapi" />
        <Card logo={snapiLogo} altText="Snapi Logo" className="snapi" />
        <Card
          logo={akkharRed}
          altText="Akkhar Red Logo"
          className="akkhar-red"
        />
        <Card logo={awdia} altText="Awdia Logo" className="awdia" />
        <Card
          logo={polyforgeBlack}
          altText="Polyforge Black Logo"
          className="flexio"
        />

        <Card logo={fekBlack} altText="Fek Black Logo" className="advize" />
        <Card logo={intact2} altText="Intact 02 Logo" className="snapi" />

        <Card logo={wokoma} altText="Wokoma Logo" className="wokoma" />
        <Card logo={intact} altText="Intact Logo" className="intact" />

        <Card logo={advizeLogo} altText="Advize Logo" className="advize" />

        <Card
          logo={akkharWhite}
          altText="Akkhar White Logo"
          className="akkhar-white"
        />
        <Card logo={flexioLogo} altText="Flexio Logo" className="flexio" />
        <Card logo={fekWhite} altText="Fek White Logo" className="fek-white" />
        <Card logo={linkUpLogo} altText="LinkUp Logo" className="linkup" />
        <Card logo={swift} altText="SwiftGo Logo" className="swift" />

        <Card logo={tiltLogo} altText="Tilt Logo" className="snapi" />
        <Card logo={snapiLogo} altText="Snapi Logo" className="snapi" />
        <Card
          logo={akkharRed}
          altText="Akkhar Red Logo"
          className="akkhar-red"
        />
        <Card logo={awdia} altText="Awdia Logo" className="awdia" />
        <Card
          logo={polyforgeBlack}
          altText="Polyforge Black Logo"
          className="flexio"
        />

        <Card logo={fekBlack} altText="Fek Black Logo" className="advize" />
        <Card logo={intact2} altText="Intact 02 Logo" className="snapi" />

        <Card logo={wokoma} altText="Wokoma Logo" className="wokoma" />
        <Card logo={intact} altText="Intact Logo" className="intact" />

        <Card logo={advizeLogo} altText="Advize Logo" className="advize" />

        <Card
          logo={akkharWhite}
          altText="Akkhar White Logo"
          className="akkhar-white"
        />
        <Card logo={flexioLogo} altText="Flexio Logo" className="flexio" />

        <Card logo={fekWhite} altText="Fek White Logo" className="fek-white" />
        <Card logo={linkUpLogo} altText="LinkUp Logo" className="linkup" />
        <Card logo={swift} altText="SwiftGo Logo" className="swift" />

        <Card logo={tiltLogo} altText="Tilt Logo" className="snapi" />
        <Card logo={snapiLogo} altText="Snapi Logo" className="snapi" />
        <Card
          logo={akkharRed}
          altText="Akkhar Red Logo"
          className="akkhar-red"
        />
        <Card logo={awdia} altText="Awdia Logo" className="awdia" />
        <Card
          logo={polyforgeBlack}
          altText="Polyforge Black Logo"
          className="flexio"
        />

        <Card logo={fekBlack} altText="Fek Black Logo" className="advize" />
        <Card logo={intact2} altText="Intact 02 Logo" className="snapi" />

        <Card logo={wokoma} altText="Wokoma Logo" className="wokoma" />
        <Card logo={intact} altText="Intact Logo" className="intact" />

        <Card logo={advizeLogo} altText="Advize Logo" className="advize" />

        <Card
          logo={akkharWhite}
          altText="Akkhar White Logo"
          className="akkhar-white"
        />
        <Card logo={flexioLogo} altText="Flexio Logo" className="flexio" />
        <Card logo={fekWhite} altText="Fek White Logo" className="fek-white" />
        <Card logo={linkUpLogo} altText="LinkUp Logo" className="linkup" />
        <Card logo={swift} altText="SwiftGo Logo" className="swift" />

        <Card logo={tiltLogo} altText="Tilt Logo" className="snapi" />
        <Card logo={snapiLogo} altText="Snapi Logo" className="snapi" />
        <Card
          logo={akkharRed}
          altText="Akkhar Red Logo"
          className="akkhar-red"
        />
        <Card logo={awdia} altText="Awdia Logo" className="awdia" />
        <Card
          logo={polyforgeBlack}
          altText="Polyforge Black Logo"
          className="flexio"
        />

        <Card logo={fekBlack} altText="Fek Black Logo" className="advize" />
        <Card logo={intact2} altText="Intact 02 Logo" className="snapi" />

        <Card logo={wokoma} altText="Wokoma Logo" className="wokoma" />
        <Card logo={intact} altText="Intact Logo" className="intact" />

        <Card logo={advizeLogo} altText="Advize Logo" className="advize" />

        <Card
          logo={akkharWhite}
          altText="Akkhar White Logo"
          className="akkhar-white"
        />
        <Card logo={flexioLogo} altText="Flexio Logo" className="flexio" />

        <Card logo={fekWhite} altText="Fek White Logo" className="fek-white" />
      </div>
      <div className="carousel-row" ref={bottomRowRef}>
        <Card
          logo={polyforgeBlack}
          altText="Polyforge Black Logo"
          className="flexio"
        />
        <Card logo={linkUpLogo} altText="LinkUp Logo" className="linkup" />
        <Card logo={flexioLogo} altText="Flexio Logo" className="flexio" />
        <Card logo={fekBlack} altText="Fek Black Logo" className="advize" />
        <Card logo={tiltLogo} altText="Tilt Logo" className="snapi" />
        <Card logo={awdia} altText="Awdia Logo" className="awdia" />
        <Card
          logo={akkharRed}
          altText="Akkhar Red Logo"
          className="akkhar-red"
        />{" "}
        <Card logo={fekWhite} altText="Fek White Logo" className="fek-white" />
        <Card logo={intact2} altText="Intact 02 Logo" className="snapi" />
        <Card logo={swift} altText="SwiftGo Logo" className="swift" />
        <Card
          logo={akkharWhite}
          altText="Akkhar White Logo"
          className="akkhar-white"
        />{" "}
        <Card logo={flexioLogo} altText="Flexio Logo" className="flexio" />
        <Card logo={awdia} altText="Awdia Logo" className="awdia" />
        <Card logo={wokoma} altText="Wokoma Logo" className="wokoma" />
        <Card logo={intact} altText="Intact Logo" className="intact" />
        <Card logo={snapiLogo} altText="Snapi Logo" className="snapi" />
        <Card
          logo={polyforgeBlack}
          altText="Polyforge Black Logo"
          className="flexio"
        />
        <Card logo={advizeLogo} altText="Advize Logo" className="advize" />
        <Card logo={tiltLogo} altText="Tilt Logo" className="snapi" />
        <Card logo={fekBlack} altText="Fek Black Logo" className="advize" />
        <Card
          logo={akkharRed}
          altText="Akkhar Red Logo"
          className="akkhar-red"
        />{" "}
        <Card logo={fekWhite} altText="Fek White Logo" className="fek-white" />
        <Card logo={advizeLogo} altText="Advize Logo" className="advize" />
        <Card logo={intact2} altText="Intact 02 Logo" className="snapi" />
        <Card logo={linkUpLogo} altText="LinkUp Logo" className="linkup" />
        <Card logo={swift} altText="SwiftGo Logo" className="swift" />
        <Card
          logo={akkharWhite}
          altText="Akkhar White Logo"
          className="akkhar-white"
        />
        <Card logo={awdia} altText="Awdia Logo" className="awdia" />
        <Card logo={wokoma} altText="Wokoma Logo" className="wokoma" />
        <Card logo={intact} altText="Intact Logo" className="intact" />
        <Card logo={snapiLogo} altText="Snapi Logo" className="snapi" />
        <Card
          logo={polyforgeBlack}
          altText="Polyforge Black Logo"
          className="flexio"
        />
        <Card logo={linkUpLogo} altText="LinkUp Logo" className="linkup" />
        <Card logo={flexioLogo} altText="Flexio Logo" className="flexio" />
        <Card logo={fekBlack} altText="Fek Black Logo" className="advize" />
        <Card logo={tiltLogo} altText="Tilt Logo" className="snapi" />
        <Card logo={awdia} altText="Awdia Logo" className="awdia" />
        <Card
          logo={akkharRed}
          altText="Akkhar Red Logo"
          className="akkhar-red"
        />{" "}
        <Card logo={fekWhite} altText="Fek White Logo" className="fek-white" />
        <Card logo={intact2} altText="Intact 02 Logo" className="snapi" />
        <Card logo={swift} altText="SwiftGo Logo" className="swift" />
        <Card
          logo={akkharWhite}
          altText="Akkhar White Logo"
          className="akkhar-white"
        />{" "}
        <Card logo={flexioLogo} altText="Flexio Logo" className="flexio" />
        <Card logo={awdia} altText="Awdia Logo" className="awdia" />
        <Card logo={wokoma} altText="Wokoma Logo" className="wokoma" />
        <Card logo={intact} altText="Intact Logo" className="intact" />
        <Card logo={snapiLogo} altText="Snapi Logo" className="snapi" />
        <Card
          logo={polyforgeBlack}
          altText="Polyforge Black Logo"
          className="flexio"
        />
        <Card logo={advizeLogo} altText="Advize Logo" className="advize" />
        <Card logo={tiltLogo} altText="Tilt Logo" className="snapi" />
        <Card logo={fekBlack} altText="Fek Black Logo" className="advize" />
        <Card
          logo={akkharRed}
          altText="Akkhar Red Logo"
          className="akkhar-red"
        />{" "}
        <Card logo={fekWhite} altText="Fek White Logo" className="fek-white" />
        <Card logo={advizeLogo} altText="Advize Logo" className="advize" />
        <Card logo={intact2} altText="Intact 02 Logo" className="snapi" />
        <Card logo={linkUpLogo} altText="LinkUp Logo" className="linkup" />
        <Card logo={swift} altText="SwiftGo Logo" className="swift" />
        <Card
          logo={akkharWhite}
          altText="Akkhar White Logo"
          className="akkhar-white"
        />
        <Card logo={awdia} altText="Awdia Logo" className="awdia" />
        <Card logo={wokoma} altText="Wokoma Logo" className="wokoma" />
        <Card logo={intact} altText="Intact Logo" className="intact" />
        <Card logo={snapiLogo} altText="Snapi Logo" className="snapi" />
      </div>
    </div>
  );
};
const Card = ({ logo, altText, className, imgClassName }) => (
  <div className={`card ${className || ""}`}>
    <img
      src={logo}
      alt={altText}
      className={`card-logo ${imgClassName || ""}`}
    />
  </div>
);

export default Carousel;
