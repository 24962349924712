import React from "react";

const PrivacyPolicy = () => {
  return (
    <div style={{ padding: "20px" }}>
      <h1>Privacy Policy</h1>
      <p>Effective Date: 09/16/2024</p>
      <p>
        At 50 Dreams Agency, we value your privacy and are committed to
        protecting your personal information. This Privacy Policy explains how
        we collect, use, and safeguard your data when you visit our website or
        use our services.
      </p>

      <h2>1. Information We Collect:</h2>
      <ul>
        <li>
          <strong>Personal Data:</strong> We may collect personal information
          such as your name, email address, and any other information you
          voluntarily provide when you contact us via email or through our
          contact form.
        </li>
        <li>
          <strong>Cookies and Usage Data:</strong> We use cookies and similar
          tracking technologies to monitor website activity, improve
          functionality, and analyze user behavior. This may include collecting
          IP addresses, browser types, and visit timestamps.
        </li>
      </ul>

      <h2>2. How We Use Your Data:</h2>
      <ul>
        <li>To respond to your inquiries and provide customer support.</li>
        <li>To improve our website and services based on user feedback.</li>
        <li>
          To monitor website performance and user interactions using Google
          Analytics and Google Search Console.
        </li>
      </ul>

      <h2>3. Google Analytics and Advertising:</h2>
      <p>
        We use Google Analytics to analyze website traffic and visitor behavior.
        Google may collect data through cookies and use it for targeted
        advertising (Google Ads). You can opt out of Google’s personalized ads
        by adjusting your ad settings at{" "}
        <a
          href="https://adssettings.google.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://adssettings.google.com
        </a>
        .
      </p>

      <h2>4. Data Sharing:</h2>
      <p>
        We do not sell or share your personal data with third parties, except as
        necessary to comply with legal obligations or to improve our services
        (e.g., Google Analytics for website performance).
      </p>

      <h2>5. Data Security:</h2>
      <p>
        We implement reasonable security measures to protect your personal data
        from unauthorized access or disclosure.
      </p>

      <h2>6. Your Rights:</h2>
      <p>
        You have the right to access, modify, or request the deletion of your
        personal data. To exercise these rights, contact us at{" "}
        <a href="mailto:info@50dreams.agency">info@50dreams.agency</a>.
      </p>

      <h2>7. Updates to This Policy:</h2>
      <p>
        We may update this Privacy Policy from time to time. Please review this
        page periodically for changes.
      </p>

      <p>
        For any questions regarding this Privacy Policy, please contact us at
        info@50dreams.agency.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
