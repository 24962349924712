import React from "react";

const TermsOfService = () => {
  return (
    <div style={{ padding: "20px" }}>
      <h1>Terms of Service</h1>
      <p>
        Welcome to 50 Dreams Agency. By using our website and services, you
        agree to the following terms and conditions.
      </p>

      <h2>1. Intellectual Property:</h2>
      <p>
        All logos, designs, and content on this website are the intellectual
        property of 50 Dreams Agency. You may not reproduce, distribute, or use
        these materials without our explicit permission.
      </p>

      <h2>2. Service Terms:</h2>
      <p>
        Our logo design services are provided based on the specifications agreed
        upon with the client. Revisions and final deliverables will be outlined
        in the project agreement. Clients are responsible for providing accurate
        and complete information necessary for the design process. Any delays in
        providing required information may affect the delivery timeline.
      </p>

      <h2>3. Limitation of Liability:</h2>
      <p>
        50 Dreams Agency is not liable for any indirect, incidental, or
        consequential damages arising from the use of our services or website.
        We are not responsible for any loss of business, data, or profit as a
        result of using our designs or website.
      </p>

      <h2>4. Payments:</h2>
      <p>
        All payments for services must be completed as per the agreement. No
        work will begin until the initial payment is received.
      </p>

      <h2>5. Termination of Service:</h2>
      <p>
        We reserve the right to refuse service or terminate a project if the
        client fails to comply with these Terms of Service or engages in illegal
        or unethical practices.
      </p>

      <h2>6. Amendments:</h2>
      <p>
        We may update these terms periodically. Continued use of our services
        constitutes acceptance of the updated terms.
      </p>

      <p>
        For further clarification, please contact us at info@50dreams.agency.
      </p>
    </div>
  );
};

export default TermsOfService;
