import React, { useState } from "react";
import "./FAQSection.css";

const FAQSection = () => {
  const [openIndex, setOpenIndex] = useState(null); // Tracks the index of the currently open FAQ

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index); // Toggles open/close state
  };

  const faqs = [
    {
      question: "Who is this for?",
      answer:
        "Our service is for entrepreneurs, small businesses, and anyone looking to create a stunning logo to represent their brand professionally.",
    },
    {
      question: "What’s your design philosophy?",
      answer:
        "We believe in creating unique, high-quality logos tailored to reflect the identity and values of your brand. We focus on simplicity, versatility",
    },
    {
      question: "How do I place an order?",
      answer:
        "Simply choose the logo package that fits your needs and complete your payment online. We’ll send you an onboarding form to gather your brand details.",
    },
    {
      question: "How long does it take to receive my first logo concepts?",
      answer:
        "You'll receive your initial logo concepts within 48 hours of completing the onboarding form.",
    },
    {
      question: "What file formats will I receive?",
      answer:
        "We provide your logo in high-quality formats such as JPEG, PNG, and vector formats (SVG, EPS), suitable for print and digital use.",
    },
    {
      question: "Can I request revisions?",
      answer:
        "Yes, all packages include revisions, with unlimited revisions available in our premium package. You can request changes until you’re completely satisfied.",
    },
    {
      question: "What if I don’t like the initial concepts?",
      answer:
        "If the initial concepts don’t meet your expectations, you can provide feedback with the revison form you will receive with the first delivery, and our designers will revise them based on your preferences.",
    },
    {
      question: "Do you offer brand guidelines?",
      answer:
        "Yes, brand guidelines are included in our premium package. They help you maintain a consistent visual identity across all platforms.",
    },

    {
      question: "What payment methods do you accept?",
      answer:
        "We accept all major credit and debit cards through Stripe, ensuring a secure and seamless payment process.",
    },
    {
      question: "Do you offer rush orders?",
      answer:
        "Yes, we offer rush services for an additional fee. If you’re in a hurry, let us know your deadline, and we’ll do our best to accommodate.",
    },
  ];

  return (
    <section id="faq" className="faq-section">
      <h2>Frequently Asked Questions</h2>
      <div className="faq-list">
        {faqs.map((faq, index) => (
          <div
            key={index}
            className={`faq-item ${openIndex === index ? "open" : ""}`}
            onClick={() => toggleFAQ(index)}
          >
            <h3>{faq.question}</h3>
            <p className={`faq-answer ${openIndex === index ? "show" : ""}`}>
              {faq.answer}
            </p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default FAQSection;
