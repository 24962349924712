import React from "react";
import { useNavigate } from "react-router-dom";
import "./App.css";

function CancelPage() {
  const navigate = useNavigate();

  return (
    <div className="centered-content">
      <div>
        <h1>Payment Cancelled</h1>
        <p>Your payment was cancelled. Please try again.</p>
        <button className="btn-homepage" onClick={() => navigate("/")}>
          Return to Home
        </button>
      </div>
    </div>
  );
}

export default CancelPage;
