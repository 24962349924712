import React from "react";
import "./StepsSection.css";
import checklistIcon from "./assets/caddie.png";
import drawIcon from "./assets/dessin.png";
import approveIcon from "./assets/approuve.png";

const StepsSection = () => {
  return (
    <section className="steps-section">
      <h2>Your Logo, Ready in 3 Easy Steps</h2>
      <div className="steps">
        <div className="step">
          <img
            className="checklist-icon"
            src={checklistIcon}
            alt="place order"
          />
          <h3>1. Place Your Order</h3>
          <p>
            Choose your logo package and complete your order. We'll send you a
            quick onboarding form to get started.
          </p>
        </div>
        <div className="step">
          <img className="draw-icon" src={drawIcon} alt="place order" />
          <h3 className="receive">2. Receive Your Concepts</h3>
          <p>
            Get your custom logo concepts within 48 hours. Review them and share
            your feedback.
          </p>
        </div>
        <div className="step">
          <img className="checklist-icon" src={approveIcon} alt="place order" />
          <h3>3. Finalize Your Logo</h3>
          <p>
            Approve your favorite design and receive the final files. Need
            adjustments? Request revisions until it’s perfect.
          </p>
        </div>
      </div>
    </section>
  );
};

export default StepsSection;
