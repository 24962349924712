import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer
      style={{
        padding: "40px",
        backgroundColor: "white",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-around",
        textAlign: "center",
      }}
    >
      <p>© 2024 50 Dreams Agency. All Rights Reserved.</p>
      <p>
        <a href="mailto:info@50dreams.agency">info@50dreams.agency</a>
      </p>
      <p>
        <Link to="/privacy-policy">Privacy Policy</Link> |{" "}
        <Link to="/terms-of-service">Terms of Service</Link>
      </p>
    </footer>
  );
};

export default Footer;
